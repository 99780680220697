<template>
  <div
    class="phone-input"
    :class="{
      'phone-input--has-text': !!phoneText,
      'phone-input--invalid': !valid,
      'phone-input--has-focus': inputHasFocus,
    }"
  >
    <div class="phone-input__wrapper">
      <input
        :name="name"
        :id="name"
        v-bind="$attrs"
        :required="required"
        type="tel"
        @focus="inputHasFocus = true"
        @blur="onBlur"
        @input="onInput"
        ref="phone"
      />
      <span class="sf-input__bar"></span>
      <label class="phone-input__label" :for="name">
        {{ label }}
      </label>
    </div>
    <div class="phone-input__error-message">
      <transition name="fade">
        <!-- @slot Custom error message of form input -->
        <slot v-if="!valid" name="error-message" v-bind="{ errorMessage }">
          <span>{{ errorMessage }}</span></slot
        >
      </transition>
    </div>
  </div>
</template>
<script>
import { focus } from "@lib/utility/directives/focus-directive.js";
import "intl-tel-input/build/css/intlTelInput.css";
import intlTelInput from "intl-tel-input";
import { getCountry } from "@lib/utility/ipUtils";

export default {
  name: "PhoneInput",
  directives: {
    focus,
  },
  inheritAttrs: false,
  props: {
    value: {
      type: [Object],
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    /**
     * Validate value of form input
     */
    valid: {
      type: Boolean,
      default: true,
    },
    /**
     * Error message value of form input. It will be appeared if `valid` is `true`.
     */
    errorMessage: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
      description: "Native input required attribute",
    },
    disabled: {
      type: Boolean,
      default: false,
      description: "Native input disabled attribute",
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler: function (val) {
        this.phoneText = val.number;
      },
    },
  },
  data() {
    return {
      telInstance: null,
      inputHasFocus: false,
      dialCode: "",
      phoneText: "",
    };
  },
  methods: {
    onInput(e) {
      if (this.$refs.phone.value) {
        this.phoneText = this.$refs.phone.value;
      }
      if (!this.telInstance) return;
      let phoneNumber = this.telInstance.getNumber();

      if (phoneNumber && phoneNumber.startsWith(this.dialCode)) {
        phoneNumber = phoneNumber
          .substring(this.dialCode.length, phoneNumber.length + 1)
          .replace(/\D/g, "");
      }

      let phoneObj = { dialCode: this.dialCode, number: phoneNumber };
      this.$emit("input", phoneObj);

      this.$emit("validate", this.telInstance.isValidNumber());
    },
    onBlur(e) {
      if (!this.telInstance) return;
      this.inputHasFocus = false;
      this.$emit("blur", e);
      this.$emit("validate", this.telInstance.isValidNumber());
    },
    onCountryChanged(e) {
      this.dialCode = `+${e.dialCode}`;
    },
  },
  mounted() {
    const input = this.$refs.phone;

    const phoneNumberUtils =
      "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.5/js/utils.min.js";

    const self = this;
    const iti = intlTelInput(input, {
      autoPlaceholder: "off",
      initialCountry: "auto",
      utilsScript:
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.19/js/utils.min.js",
      geoIpLookup: async function (success, failure) {
        try {
          self.countryCode = self.countryCode || (await getCountry());
          success(self.countryCode);
        } catch (error) {
          failure(error);
        }
      },
    });
    this.telInstance = iti;

    input.addEventListener("countrychange", () => {
      const countryCode = iti.getSelectedCountryData();
      this.onCountryChanged(countryCode);
    });

    iti.promise.then(() => {
      const initialCountryData = iti.getSelectedCountryData();
      if (initialCountryData) this.onCountryChanged(initialCountryData);
      if (this.value && this.value.number) {
        iti.setNumber(this.value.number);
        this.onInput(null);
      }
    });
  },
  beforeDestroy() {
    if (this.telInstance) {
      this.telInstance.destroy();
      this.telInstance = null;
    }
  },
};
</script>
<style lang="scss">
@use "~@lib/styles/helpers" as *;

.phone-input {
  position: relative;
  box-sizing: border-box;
  width: var(--input-width);
  height: var(--input-height);
  &__label {
    position: absolute;
    display: var(--input-label-display);
    top: var(--input-label-top, 50%);
    left: var(--input-label-left, 64px);
    color: var(--input-label-color, inherit);
    transform: var(
      --input-label-transform,
      translate3d(0, calc(var(--input-label-top, 50%) * -1), 0)
    );
    transition: var(
      --input-label-transition,
      top 150ms linear,
      left 150ms linear,
      font-size 150ms linear
    );
    &::after {
      content: var(--input-label-required);
      color: var(--input-label-color, var(--c-primary));
    }
    @include font(
      --input-label-font,
      var(--font-normal),
      var(--font-lg),
      1,
      var(--font-family-secondary)
    );
  }
  &__bar {
    position: relative;
    display: var(--input-bar-display, block);
    &::before,
    &::after {
      position: absolute;
      bottom: 0;
      width: var(--input-bar-width, 0);
      height: 1px;
      background: var(--input-bar-background, var(--c-primary));
      transition: width 150ms linear;
      content: "";
    }
    &::before {
      left: 50%;
    }
    &::after {
      right: 50%;
    }
  }
  &__error-message {
    color: var(--input-error-message-color, var(--c-danger));
    height: calc(var(--font-xs) * 1.2);
    @include font(
      --input-error-message-font,
      var(--font-medium),
      var(--font-xs),
      1.2,
      var(--font-family-secondary)
    );
  }
  &__wrapper,
  input {
    width: 100%;
    height: 100%;
  }
  &__wrapper {
    position: relative;
    margin: var(--input-margin, 0 0 var(--spacer-xs) 0);
  }
  input {
    display: block;
    box-sizing: border-box;
    padding: var(--input-padding, var(--spacer-sm) 0 var(--spacer-xs) 0);
    @include border(--input-border, 0 0 1px 0, solid, var(--c-light));
    background: var(--input-background);
    color: var(--input-color, var(--c-text));
    width: 100%;
    @include font(
      --input-font,
      var(--font-normal),
      var(--font-lg),
      1,
      var(--font-family-secondary)
    );
    text-align: var(--input-text-align);

    &:focus {
      & ~ * {
        --icon-color: var(--c-primary);
        --input-password-icon-background: var(--c-primary);
        --input-label-top: 0;
        --input-label-color: var(--c-primary);
        --input-label-font-size: var(--font-2xs);
        --input-bar-width: 50%;
        --input-error-message-color: var(--c-primary);
      }
    }

    &:required {
      & ~ * {
        --input-label-required: " *";
      }
    }
  }
  &--has-focus {
    --input-label-top: 0;
    --input-label-left: 0;
    --input-label-font-size: var(--font-2xs);
    --input-label-color: var(--c-primary);
  }
  &--invalid {
    --input-border-color: var(--c-danger);
    input {
      &:focus {
        & ~ * {
          --input-bar-background: var(--c-danger);
          --input-label-color: var(--c-danger);
        }
      }
    }
  }
  &--has-text {
    --input-label-top: 0;
    --input-label-font-size: var(--font-2xs);
    --input-label-left: 0;
  }
  &--filled {
    --input-background: var(--c-light);
    --input-font-size: var(--font-xs);
    --input-padding: var(--spacer-xs);
    --input-label-left: var(--spacer-xs);
    --input-label-font-size: var(--font-xs);
    --input-label-color: var(--c-secondary-variant);
    --input-label-transition: color 150ms ease;
    &.phone-input--has-text {
      --input-label-display: none;
    }
    input {
      &:focus {
        & ~ * {
          --input-bar-width: unset;
          --input-label-top: 50%;
          --input-label-font-size: var(--font-xs);
          --input-label-color: var(--c-text);
        }
      }
    }
  }

  input {
    padding-left: 10px;
  }

  .vti__dropdown {
    padding-bottom: 0;
  }

  .iti {
    width: 100%;
    border-radius: 0.25rem;

    input {
      width: 100%;
    }

    input:focus {
      outline: none;
      border-bottom: 2px solid var(--c-primary);
    }

    .iti__selected-flag:focus {
      outline: none;
    }
  }
}
</style>
